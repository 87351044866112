class AweSlider extends HTMLElement {
  constructor() {
    super()

    this.slideDuration = 500 // transition length when sliding
    this.productCount = 0 // number of elements in slider
    this.showSiblings = false,
    this._productIdx = 0 // index of the selected element
    this._scrollOffset = 0 // current offset of the container, in px
    this._scrollStep = 0 // amount to move when sliding from one product to another
    this._positionOffset = 0 // offset for the container's left property, in px
    this._swipeStartX = 0 // initial X coordinates on touchStart event.
    this._offsetBeforeSwipe = 0 // temp store the offset on touchStart event
    this.SWIPE_MIN_DIST = 16 // minimum distance in px for a swipe to count (direction != 0)
    this._slideItv = 0 // interval Id for automatic sliding
    this._delayedSlideWip = false // true delayed slide in progress. Used to throttle handler.
    this._hasClones = false // wether slotted elements are clone for sliding.

    this._resizeTimeoutId = 0 // timeout id for resize throtling
    this._resizeWidth = { ctnr: 0, content: 0 }

    const left = document.createElement('button')

    left.classList.add('slide-btn', 'left')
    left.setAttribute('aria-label', i18n.get('AWE-SLIDER.SELECT_PREVIOUS_PRODUCT'))

    left.innerHTML = `
    <svg class="picto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0  15 25" role="presentation">
      <path id="arrow" d="M1 4A1 1.25-45 014 1L14 11A2.1 2 0 0114 14L4 24A1 1.25 45 011 21L9.5 12.5Z"/>
    </svg>
    `

    const right = left.cloneNode(true)

    right.classList.remove('left')
    right.classList.add('right')
    right.setAttribute('aria-label', i18n.get('AWE-SLIDER.SELECT_NEXT_PRODUCT'))

    const ctnr = document.createElement('div')

    ctnr.classList.add('ctnr')
    ctnr.id = 'ctnr'
    ctnr.setAttribute('role', 'presentation')
    const content = document.createElement('div')

    content.classList.add('content')
    content.setAttribute('role', 'presentation')
    content.append(document.createElement('slot'))

    content.style.left = 0

    ctnr.appendChild(content)
    this._contentNd = content

    const style = document.createElement('style')

    style.textContent = `
    :host{
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;
      justify-content: stretch;
      align-items: stretch;
      flex-flow: column;
    }
    .ctnr{
      flex: 0 1 auto;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    .content{
      position: relative;
      height: 100%;
      left: 50%;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: stretch;
      width: calc(var(--product-count, 1) * 100%);
      transition: none var(--slide-duration) ease 0s;
    }
    .siblings .content{
      width: auto;
    }

    .slide-btn{
      position: absolute;
      height: 100%;
      cursor: pointer;
      flex: 0 0 auto;
      padding: 0;
      top: 0;
      z-index: 10;
      outline: none;
      border:none;
      background: none;
    }
    :host([product-count="1"]) .slide-btn {
      display: none;
    }

    .slide-btn:focus:after{
      outline: 1px dotted black;
      content: '';
      width: calc(100% - 1px);
      height: 50px;
      display: var(--slider-outline-display, block);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .left{
      left: 0;
      padding: 0 var(--slider-arrow-inner-padding, 5px) 0 var(--slider-arrow-outer-padding, 15px);
    }
    .left .picto{
      transform: scaleX(-1);
      fill: var(--slider-arrow-left-color, var(--slider-arrow-color, #000));
    }
    .left:focus:after{
      right: 0;
    }
  
    .right{
      right: 0;
      padding: 0 var(--slider-arrow-outer-padding, 15px) 0 var(--slider-arrow-inner-padding, 5px);
    }
    .right .picto {
      fill: var(--slider-arrow-right-color, var(--slider-arrow-color, #000));
    }
    .right:focus:after{
      left: 0;
    }
  
    .picto{
      width: var(--slider-arrow-width, 30px);
      height: var(--slider-arrow-height, 30px);

      background-repeat: no-repeat;
      background-image:var(--slider-arrow-image, none);
    }
    path{
      display: var(--slider-default-arrow-display, inline);
    }

    /* define some basic style for slotted nodes*/
    ::slotted(*){
      height: 100%;
      overflow: hidden;
      opacity: var(--slider-siblings-opacity, .25);
      width: var(--pdt-width, 100%);
      transition: transform var(--slide-duration), opacity var(--slide-duration);
    }
    ::slotted(.selected){
      opacity: 1;
    }

    .siblings ::slotted(*){
      transform: scale(.8);
    }
    .siblings ::slotted(.selected){
      transform: scale(1);
    }
    `

    // :aria-activedescendant="'pdt_'+ productIdx"
    this.role = 'listbox'
    this.ariaOrientation = 'horizontal'
    this.attachShadow({ mode: 'open' })
    this.shadowRoot.append(style, left, ctnr, right)

    left.addEventListener('click', evt => { this._select(evt, -1) }, { passive: true })
    right.addEventListener('click', evt => { this._select(evt, 1) }, { passive: true })

    // Observe both container and content nodes, recompute step/offset/position on change.
    // dimensions provided by observer are kept between calls, in case later calls are only one of the observed nodes.
    if (window.ResizeObserver) {
      const obs = new ResizeObserver(entries => {
        let hasChange = false

        for (let i = 0; i < entries.length; i++) {
          const e = entries[i]
          const w = e.contentRect && Math.floor(e.contentRect.width)

          if (w && e.target === this._contentNd && this._resizeWidth.content != w) {
            this._resizeWidth.content = w
            hasChange = true
          }
          else if (w && e.target === this._contentNd.parentElement && this._resizeWidth.ctnr != w) {
            this._resizeWidth.ctnr = w
            hasChange = true
          }
        }

        if (!hasChange) return // dont do anything if no change

        // throttle resize update
        if (!this._resizeTimeoutId) {
          this._resizeTimeoutId = setTimeout(() => {
            this.init(this._resizeWidth.ctnr, this._resizeWidth.content)
            this._resizeTimeoutId = 0
          }, 50)
        }
      })

      obs.observe(this._contentNd.parentElement)
      obs.observe(this._contentNd)
    }
  }

  connectedCallback() {
    if (!this.isConnected) return

    this.setAttribute('tabindex', 0)
    this.setAttribute('aria-label', i18n.get('AWE-SLIDER.PRODUCT_SLIDER_LBL'))

    // add data-idx attribute if not set
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].dataset.idx == null)
        this.children[i].dataset.idx = i
    }

    if (this.hasAttribute('product-count')) {
      const count = parseInt(this.getAttribute('product-count'), 10)

      if (!isNaN(count) && count !== this.productCount)
        this.productCount = count
    }
    this.style.setProperty('--product-count', Math.max(this.productCount, 1))
    this._reorderNodes()

    const showSibs = this.getAttribute('show-siblings')

    // show-siblings set if attribute exists without value or is true
    this.showSiblings = this.productCount > 2 && (showSibs === '' || showSibs === 'true')
    this.shadowRoot.getElementById('ctnr').classList.toggle('siblings', this.showSiblings)
    this.init() // Compute initial values

    if (this.hasAttribute('slide-duration')) {
      const duration = parseInt(this.getAttribute('slide-duration'), 10)

      if (!isNaN(duration) && duration !== this.slideDuration)
        this.slideDuration = duration
    }
    this.style.setProperty('--slide-duration', `${this.slideDuration}ms`)

    if (this.hasAttribute('slide-interval')) {
      let interval = parseInt(this.getAttribute('slide-interval'), 10)

      if (!isNaN(interval) && interval > 0)
        interval = Math.max(interval, 5e3) // enforce minimum 5s interval
      else
        interval = 0

      // enable automatic sliding if option is enabled
      if (interval > 0 && this.productCount > 1) {
        this._slideItv = setInterval(() => {
          this._select(null, 1, true)
        }, interval)
      }
      else if (this._slideItv > 0) {
        clearInterval(this._slideItv)
        this._slideItv = 0
      }
    }

    if (this.productCount === 2 || this.productCount <= 4 && this.showSiblings) {
      // Add clones for smooth nav.
      // On slide, we can just translate the container and make adjustements after transition is done.
      const leftClone = this.lastElementChild.cloneNode(true)

      if (this.productCount === 3) {
        const rightClone = this.firstElementChild.cloneNode(true)

        this.appendChild(rightClone)
      }
      else if (this.productCount === 2)
        // for 2 products, need to adjust container width with one pseudo-product
        this.style.setProperty('--product-count', this.productCount + 1)

      this.insertBefore(leftClone, this.firstElementChild) // insert AFTER we clone the first element.
      this._positionOffset -= this._scrollStep
      this._update()
      this._hasClones = true
    }

    if (this.productCount > 1) {
      this.addEventListener('keydown', this._arrowNav, { passive: true })
      this.addEventListener('touchstart', this._swipeStart, { passive: true })
      this.addEventListener('touchend', this._swipe, { passive: true })
    }

    if (this.showSiblings) {
      for (let i = 0; i < this.childElementCount; i++) {
        this.children[i].addEventListener('click', e => { this._sibClick(e) })
      }
    }

    // add transition rule after a delay, so as to not apply to initial rendering.
    setTimeout(() => {
      this._contentNd.style.transitionProperty = 'transform'
    }, 1e2)
  }

  // Computes basic hs to adjust animation.
  // containerWidth, contentWidth: width of ctnr and content nodes  in px, as provided by resizeObserver
  init(containerWdith, contentWidth) {
    const ctnrWidth = containerWdith || this._contentNd.parentElement.getBoundingClientRect().width

    let newScrollStep

    if (this.showSiblings) {
      newScrollStep = ctnrWidth / 3
      this._contentNd.parentElement.style.setProperty('--pdt-width', `${newScrollStep.toFixed(2)}px`)
    }
    else
      newScrollStep = ctnrWidth

    this._scrollStep = newScrollStep
    let pdtOffset = 0

    if  (this.childElementCount % 2 === 0)
      pdtOffset = this._scrollStep / 2

    const cttWidth = contentWidth || this._contentNd.getBoundingClientRect().width

    const scroll = -cttWidth / 2 + pdtOffset
    const position = ctnrWidth / 2
    const hasChanges = scroll !== this._scrollOffset || position !== this._positionOffset

    this._scrollOffset = -cttWidth / 2 + pdtOffset
    this._positionOffset = ctnrWidth / 2
    // console.debug(`scrollOffset: ${this._scrollOffset}\t\tpositionOffset: ${this._positionOffset}`)

    if (hasChanges)
      this._update()

    return hasChanges
  }

  // moves slider to show to next/previous product
  // direction: 1 to show next product, -1 for previous product
  _select(event, direction, auto = false) {
    if (event) {
      event.stopPropagation()

      if (this._slideItv != 0) {
        clearInterval(this._slideItv)
        this._slideItv = 0
      }
    }

    // a click on mobile on the left/right button triggers touchstart & touchend events, with the same coordinate so direction is 0.
    // Just ignore it, as the click event is triggered right after, and can processed normally.
    if (!direction || this._delayedSlideWip ) return

    // get index of product to show
    let nextIdx = (this._productIdx + direction) % this.productCount

    if (nextIdx < 0)
      nextIdx = this.productCount - 1

    this._scrollOffset += this._scrollStep * -direction

    this._update()
    this._slide(direction)

    const startEnd = direction > 0
    && (this.productCount === 3 && this.showSiblings || this.productCount == 2)

    const oldSel = this._getNode(this._productIdx, startEnd)

    oldSel.classList.remove('selected')
    oldSel.setAttribute('aria-selected', false)
    this._productIdx = nextIdx // set new selected node
    const newSel = this._getNode(this._productIdx, startEnd)

    newSel.classList.add('selected')
    newSel.setAttribute('aria-selected', true)

    this.dispatchEvent(new CustomEvent('slider-select', { detail: { idx: this._productIdx, auto }, bubbles: true }))
  }

  _slide(direction) {
    this._delayedSlideWip = true
    setTimeout(() => {
      this._positionOffset += this._scrollStep * direction

      if (direction > 0) {
        let node = this.firstElementChild

        if (this._hasClones) {
          this.firstElementChild.remove()
          node = this.children[this.productCount % 2].cloneNode(true)
          node.addEventListener('click', e => { this._sibClick(e) })
        }
        this.appendChild(node)
      }
      else {
        let node = this.lastElementChild

        if (this._hasClones) {
          this.lastElementChild.remove()
          node = this.children[this.childElementCount - 1 - (this.productCount % 2)].cloneNode(true)
          node.addEventListener('click', e => { this._sibClick(e) })
        }
        this.insertBefore(node, this.firstElementChild)
      }

      this._update()
      this._delayedSlideWip = false
    }, this.slideDuration)
  }

  // On touch start, prepare for swipe
  _swipeStart(e) {
    e.stopPropagation()

    if (this._slideItv != 0) {
      clearInterval(this._slideItv)
      this._slideItv = 0
    }

    this._swipeStartX = e.touches[0].screenX // x coordinate when beginning to swipe

    this._offsetBeforeSwipe = this._scrollOffset // store offset as it is before the swipe starts
    this.style.setProperty('--slide-duration', `0ms`) // no transition when moving for touchmove preview

    // bind touchmove event so that we can slide a bit as the finger moves
    this.addEventListener('touchmove', this._previewMove, { passive: true })
  }

  // swipe: show
  _swipe(e) {
    this.style.setProperty('--slide-duration', `${this.slideDuration}ms`) // revert transition to its normal value
    this._scrollOffset = this._offsetBeforeSwipe // reset offset to before swipe, so that calculations in select method use the correct starting point
    const length = this._swipeStartX - e.changedTouches[0].screenX

    let direction = 0

    // use direction 0 if the distance moved is below the threshold
    if (Math.abs(length) > this.SWIPE_MIN_DIST) {
      direction = Math.sign(length)
    }

    this._select(e, direction)
    this.removeEventListener('touchmove', this._previewMove)
  }

  // when moving with finger on screen, start sliding as the finger moves
  _previewMove(e) {
    e.stopPropagation()

    let len = this._swipeStartX - e.touches[0].screenX // length between swipe start and now
    const direction = len > 0 ? 1 : -1

    // reduce scroll offset if moved more than 1 product's width
    if (Math.abs(len) > this._scrollStep)
      len = (this._scrollStep + (Math.abs(len) - this._scrollStep) * .2) * direction

    // move container in the same proportion as the finger moved
    this._scrollOffset = this._offsetBeforeSwipe - len
    this._update()
  }

  _update() {
    this._contentNd.style.transform = `translateX(${this._scrollOffset}px)`
    this._contentNd.style.left = this._positionOffset + 'px'
  }

  // current selection must be in the middle of the array, or the index before if product count is even.
  // Move product to adjust selection: first elements are moved at the end, or last elements moved in first position.
  // We only manipulate the DOM nodes, not the underlying products array.
  _reorderNodes() {
    let selectedNodeIdx = -1

    // if products array is updated, the existing node list may already be rearranged. Selected product idx (in array) may be
    // different than its actual position in node array. So we get its index in node array rather than its index in product array.
    const idx = this._productIdx.toString()

    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].dataset.idx === idx) {
        selectedNodeIdx = i
        break
      }
    }

    if (selectedNodeIdx === -1) {
      console.warn('Cannot find selected product in node list.') // eslint-disable-line no-console

      return
    }

    const targetIdx  = Math.ceil(this.productCount / 2 ) - 1,
      dist = selectedNodeIdx - targetIdx

    for (let i = 0; i < Math.abs(dist); i++) {
      if (dist > 0)
        this.appendChild(this.firstElementChild)
      else
        this.insertBefore(this.lastElementChild, this.firstElementChild)
    }
  }

  _getNode(idx, startEnd) {
    let nd, i = 0, ri
    const targetIdx = idx.toString(10)

    do {
      ri = startEnd ? (this.childElementCount - i - 1) : i
      if (this.children[ri].dataset.idx === targetIdx)
        nd = this.children[ri]
    }
    while (!nd && ++i < this.children.length)

    return nd
  }

  _arrowNav(e) {
    if (e.code === 'ArrowLeft')
      this._select(e, -1)
    else if (e.code === 'ArrowRight')
      this._select(e, 1)
  }

  // handler for click on siblings: move selection
  _sibClick(e) {
    const selected = this.getElementsByClassName('selected')[0]

    if (selected && selected.nextElementSibling === e.currentTarget)
      this._select(e, 1)
    else if (selected && selected.previousElementSibling === e.currentTarget)
      this._select(e, -1)
  }
}
customElements.define('awe-slider', AweSlider)
